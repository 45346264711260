export class T {
    public static readonly contact = 'Contact';
    public static readonly student = 'Student';

    public static readonly date_created_default = 'Date Created';
    public static readonly date_created_student = 'Date Created (Student)';
    public static readonly enquiry_date_range = 'Enquiry Date Range';
    public static readonly starting_year = 'Starting Year';
    public static readonly intake_year_level = 'et.intakeYearLevel';
    public static readonly intakeYearLevelTag = 'et.intakeYearLevelTag';
    public static readonly stage = 'Stage';
    public static readonly status = 'Status';
    public static readonly student_status = 'Student Status';
    public static readonly alumni = 'Alumni';
    public static readonly yearlyComparisonRange = 'Select Range';
    public static readonly compareStartingYear = 'Compare Starting Year';
    public static readonly eventType = 'Event Type';
    public static readonly eventYears = 'Year';
    public static readonly ptYear = 'Year';
    public static readonly appointmentType = 'Appointment Type';
    public static readonly displayedEvents = 'Display Events';

    public static readonly contact_type = 'Contact Type';
    public static readonly contact_last_name = 'Contact Last Name';
    public static readonly contact_address = 'Contact Address';
    public static readonly contact_city = 'Suburb/City';
    public static readonly contact_country = 'Country';
    public static readonly contact_state = 'State';
    public static readonly contact_mobile = 'Contact Mobile';
    public static readonly contact_home_phone = 'Contact Home Phone';
    public static readonly contact_work_phone = 'Contact Work Phone';
    public static readonly contact_receive_update_email = 'Receive Updates by Email';
    public static readonly contact_receive_update_sms = 'Receive Updates by SMS';

    public static readonly student_last_name = 'Student Last Name';
    public static readonly student_gender = 'Gender';
    public static readonly student_financial_aid = 'Financial Aid';
    public static readonly student_religion = 'Religion';
    public static readonly student_sibling = 'Sibling (Legacy)';
    public static readonly family_connection = 'Family Connection';
    public static readonly has_future_siblings = 'Future Siblings';
    public static readonly student_exported_to_sms = 'Exported to Student Information System';
    public static readonly student_other_interests = 'Other Interests';
    public static readonly student_has_special_needs = 'Has Additional Needs';
    public static readonly student_additional_needs = 'Additional Needs';
    public static readonly student_flagged = 'Flagged';
    public static readonly student_current_school_year = 'Current School Year';
    public static readonly relationship_type = 'Relationship Type';
    public static readonly year_and_month_of_birth = 'Year and Month of Birth';
    public static readonly birth_month = 'Birth Month';
    public static readonly birth_date = 'Birth Date';
    public static readonly current_school = 'Current School';
    public static readonly current_school_status = 'Current School Status';
    public static readonly current_school_Type = 'Current School Type';
    public static readonly lead_source = 'Lead Source';
    public static readonly hear_about_us = 'How did you hear about us';
    public static readonly student_type = 'Student Type';
    public static readonly international_student = 'International Student';
    public static readonly activity = 'Activity';
    public static readonly studentTransferStatus = 'Transfer Status';
    public static readonly done = 'Done';
    public static readonly failed = 'Failed';
    public static readonly inProgress = 'In Progress';

    public static readonly event = 'Event';
    public static readonly personalTour = 'Appointment';

    public static readonly form = 'Form';
    public static readonly applicationStatus = 'Application Status';

    public static readonly registeredContacts = 'Registered Contacts';
    public static readonly attendedContacts = 'Attended Contacts';

    public static readonly countryOfOrigin = 'Country of Origin';
    // Important keep this text on one line since it is used in javascript and crashes the code otherwise
    public static readonly underMaintenanceMsgForForms = `We are sorry for the inconvenience, but our forms are currently undergoing maintenance. Please check back again shortly.`;

    public static readonly msgRequired = 'This field is required';
    public static readonly msgNoPrimaryContact = 'Please select at least one contact to be the primary contact.';
    public static readonly msgFeeResponsibilityNone = 'At least one or more Parent/Guardian must have Fee Responsibility';
    public static readonly msgFeeResponsibilityOnlyShared = 'More than one Parent/Guardian must have Shared Fee Responsibility';
    public static readonly msgFeeResponsibilityOver = 'Only one Parent/Guardian may have Full Fee Responsibility';
    public static readonly msgAtLeastOneSignature = 'At least one signature must be entered';

    public static readonly funnelMetrics = 'Funnel Metrics';
    public static readonly totalByStage = 'Total by Stage';

    public static readonly unknown = 'Unknown';

    public static readonly duplicateMode = 'duplicateMode';

    public static readonly disableViewDetailsBtnTooltip = 'Select all legends to view details';
}
