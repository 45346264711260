<div class="row">
    <mat-checkbox
        *ngFor="let option of options"
        [ngClass]="columnWidth ? 'col-sm-' + columnWidth : 'col'"
        [checked]="isOptionSelected(option.id)"
        [disabled]="option.disabled"
        (change)="onCheckboxChange($event, option.id)"
    >
        {{ option.name }}
    </mat-checkbox>
</div>
