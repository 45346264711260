import { CommonModule } from '@angular/common';
import { Component, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { ICheckboxItem } from 'app/common/interfaces';
import * as _ from 'lodash';

@Component({
    selector: 'app-checkbox-group',
    standalone: true,
    templateUrl: './checkbox-group.component.html',
    styleUrls: ['./checkbox-group.component.scss'],
    imports: [CommonModule, MatCheckboxModule],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => CheckboxGroupComponent),
            multi: true,
        },
    ],
})
export class CheckboxGroupComponent implements ControlValueAccessor {
    @Input() options: ICheckboxItem[];
    @Input() columnWidth?: string;

    value: (number | string)[] = [];
    touched = false;
    disabled = false;

    onChange = (value: (number | string)[]) => {};
    onTouched = () => {};

    onCheckboxChange(event: any, option: number | string): void {
        this.markAsTouched();
        const currentValues = [...this.value];

        if (event.checked) {
            currentValues.push(option);
        } else {
            const index = currentValues.indexOf(option);
            if (index > -1) {
                currentValues.splice(index, 1);
            }
        }

        this.value = currentValues;
        this.onChange(this.value);
    }

    isOptionSelected(option: number | string): boolean {
        return this.value.includes(option);
    }

    writeValue(value: string[]): void {
        this.value = value || [];
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    markAsTouched(): void {
        if (!this.touched) {
            this.onTouched();
            this.touched = true;
        }
    }
}
